import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import LinkText from '../../components/Blocks/LinkText/linkText';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import HeroWithPhotoAndHeader from '../../components/HeroWithPhotoAndHeader/heroWithPhotoAndHeader';
import Layout from '../../components/layout';
import LinkBlock from '../../components/LinkBlock/linkBlock';
import SEO from '../../components/SEO/seo';

const InRainbowsAlbumPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );

  return (
    <Layout backto="/tools" noheader notopmargin >
        <SEO lang="ru" title={'Dashboard'} keywords={['album', 'album review', 'Artyom Alekseev', 'Артём Алексеев', 'In Rainbows', 'Radiohead']} description="Create tasks faster. Anytime. Anywhere.">
            <link rel="alternate" href={`${site.siteMetadata.siteUrl}/tools/fedro`} hrefLang="en" key="en"/>
        </SEO>
        <HeroWithPhotoAndHeader blHeader backto="/tools" desc="Create tasks faster. Anytime. Anywhere." imageURL={'dashboard.png'} heading="Notion Dashboard"/>

        <Section heading="Source">
            <LinkBlock href="https://figma.com" heading="The Design Productivity Blueprint" para="Figma content"/>
        </Section>

        <Section heading="The Idea">
            <Paragraph>When you grow as a designer, you want to optimize every second of finding something. You want to give more time to the actual design, not to navigate into million of artboards.</Paragraph>
            <Paragraph>You can use these covers to find the needed design faster.</Paragraph>
        </Section>

    </Layout>
  ); };

export default InRainbowsAlbumPage;
